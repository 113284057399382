<template>
  <div class="main">
    <van-nav-bar
      class="my_nav"
      title="404"
      left-arrow
      fixed
      @click-left="$back($event)" />
    <div class="bg">
      <van-image
        width="100%"
        height="100%"
        fit="cover"
        :src="require('../assets/img/404.png')" />
    </div>
  </div>
</template>

<script>
    export default {   
        data() {
            return {}
        },
        computed: {},
        watch: {},
        created() {
        },
        mounted() {
        },
        methods: {
          back() {
            this.$router ? this.$router.back() : window.history.back();
          }, 
        }
    }
</script>

<style lang="scss" scoped>
  .my_nav {
    >>> .van-nav-bar__left .van-icon-arrow-left.van-icon {
      font-size: .3rem;
    }
    >>> .van-nav-bar__title {
      font-size: .26rem;
    }
  }
  .page {
    overflow-y: hidden !important;

    .main {
      .bg {
        width: 100%;
        height: 100%;
      }
    }
  }

</style>
